import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import BlogBanner from "../components/blog-banner/blogBanner"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PageTemplate = ({ data: { page, allPage } }) => {

  const pages = allPage.nodes

  return (
    <Layout navbarTheme="light" className="bg-blue">
      {/* <Seo title={page.title} description={page.excerpt.replace(/(<([^>]+)>)/gi, "")} /> */}
      <Seo
        title={page.seo.title}
        description={page.seo.metaDesc}
        canonical={`https://tio.ist${page.seo.canonical}`}
        schema={JSON.parse(page.seo.schema.raw)}
      />
      <div>
        <article>
          <h1 className="text-[2.75rem] md:text-8xl lg:text-9xl font-extrabold text-center text-white pt-16 md:pt-32 mx-5">
            {page.title}
          </h1>
          {!!page.content && (
            <section
              className="leading-8 text-white text-lg seo-page overflow-hidden"
              itemProp="articleBody"
            >
              {parse(page.content)}
            </section>
          )}
        </article>
      </div>
      <div className="bg-blue py-10 lg:p-10">
        <div className="container text-center mx-auto md:py-10 lg:p-10 relative">
          <div className="">
            <h2 className="text-xl text-center pb-16 font-thin text-lightBlue">Get Info About</h2>
          </div>
          {pages &&
            <div className="flex flex-col space-y-8 lg:space-y-16">
              {pages.map(page => (
                <Link to={page.uri} className="text-white text-3xl md:text-6xl font-bold">
                  <span className="border-b-2 border-transparent hover:border-white">{page.seo.title}</span>
                </Link>
              ))}
            </div>}
        </div>
      </div>
      <BlogBanner />
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query pageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      uri
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        schema {
          articleType
          pageType
          raw
        }
      }
      content
      excerpt
      author {
        node {
          avatar {
            url
          }
          id
          name
          uri
          description
        }
      }
      categories {
        nodes {
          id
          name
          link
        }
      }
      tags {
        nodes {
          id
          link
          name
          slug
          posts {
            nodes {
              id
              title
            }
          }
        }
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    allPage: allWpPage(
      filter: { language: { slug: { eq: "en" } }, id: { ne: $id }, categories: {nodes: {elemMatch: {slug: {eq: "no-case-study"}}}}}
    ) {
      nodes {
        id
        uri
        title
        seo {
          title
        }
      }
    }
  }
`